import styled from "styled-components"

export const BlackscreenWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 202;

  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  background: #000;
`
