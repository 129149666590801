import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import copy from "copy-text-to-clipboard"

import ProgressBar from "../../ProgressBar"
import Button from "../../Button"
import UploadChallenge from "./upload-challenge"
import ModalQuiz from "./modal-quiz"
import HelpTooltip from "../../HelpTooltip"
import vectorCopy from "./vector_copy.svg"

import { toastActions } from "../../../redux/toast"
import zeusAPI from "../../../api/zeus"
import {
  selectPlaygroundProfile,
  fetchPlayerData,
} from "../../../redux/slices/playground"
import { getAuthUser } from "../../../redux/slices/session/selectors"
import { Styles } from "./style"

const PerusahaanChallenge = ({
  id,
  perusahaanSlug,
  perusahaanName,
  perusahaanSosmed,
}) => {
  // Handle special cases
  const newPerusahaanSosmed = {}
  Object.keys(perusahaanSosmed).forEach((key) => {
    newPerusahaanSosmed[key.toLowerCase()] = perusahaanSosmed[key]
  })
  perusahaanSosmed = newPerusahaanSosmed

  const [loadingQuiz, setLoadingQuiz] = useState(true)
  const [quizList, setQuizList] = useState([])
  const [finishedDict, setFinishedDict] = useState({})
  const [uploadedDict, setUploadedDict] = useState({})
  const [challengeClosedDict, setChallengeClosed] = useState({})
  const [pointGivenDict, setPointGiven] = useState({})
  const [maxPoint, setMaxPoint] = useState(999)
  const [currentPoint, setCurrentPoint] = useState(0)
  const [openedChallenge, setOpenChallenge] = useState(-1)

  const prevOpenedChallenge = useRef()

  const { account } = useSelector((state) => ({
    account: getAuthUser(state),
  }))
  const player = useSelector(selectPlaygroundProfile)

  const dispatch = useDispatch()

  useEffect(() => {
    prevOpenedChallenge.current = openedChallenge
  })
  const prevChallenge = prevOpenedChallenge.current

  useEffect(() => {
    if (account) {
      dispatch(fetchPlayerData())
    }
    zeusAPI.playground.getCompanyChallenges(id).then((challenges) => {
      setLoadingQuiz(false)
      setQuizList(challenges)
    })
  }, [])

  useEffect(() => {
    let pointSum = 0
    quizList.forEach((val) => {
      pointSum += val.points
    })
    setMaxPoint(pointSum)
  }, [quizList])

  useEffect(() => {
    if (
      openedChallenge >= 0 &&
      challengeClosedDict[quizList[openedChallenge].id]
    ) {
      setOpenChallenge(-1)
      toastActions.showToast("Challenge not opened or already expired")
    }
  }, [challengeClosedDict])

  useEffect(() => {
    if (
      account &&
      openedChallenge === -1 &&
      (typeof prevChallenge === "undefined" ||
        prevChallenge === -1 ||
        (quizList[prevChallenge].challenge_type === "Upload" &&
          !finishedDict[prevChallenge]))
    ) {
      Promise.all(
        quizList.map((oneQuiz) => zeusAPI.playground.getChallenge(oneQuiz.id))
      ).then((values) => {
        let pointCurrent = 0
        const newFinishedDict = {}
        const newUploadedDict = {}
        const newChallengeClosed = {}
        const newPointGiven = {}
        values.forEach((val) => {
          newFinishedDict[val.id] = val.finished
          newChallengeClosed[val.id] = val.challenge_closed
          newPointGiven[val.id] = val.point_given
          if (val.point_given) {
            pointCurrent += val.point_given
          }
          if (val.uploaded_url) {
            newUploadedDict[val.id] = val.uploaded_url
          }
        })
        setCurrentPoint(pointCurrent)
        setFinishedDict(newFinishedDict)
        setUploadedDict(newUploadedDict)
        setChallengeClosed(newChallengeClosed)
        setPointGiven(newPointGiven)
      })
    }
  }, [quizList, openedChallenge])

  const quiz = quizList[openedChallenge]
  if (openedChallenge >= 0) {
    if (!account) {
      setOpenChallenge(-1)
      toastActions.showToast(
        "You need to login before attempting this challenge"
      )
      return <></>
    }

    if (quiz.challenge_type === "Upload") {
      return (
        <UploadChallenge
          quiz={quizList[openedChallenge]}
          uploadedUrl={uploadedDict[quiz.id]}
          setOpenChallenge={setOpenChallenge}
          perusahaanName={perusahaanName}
          perusahaanSosmed={perusahaanSosmed}
        />
      )
    }
  }

  return (
    <Styles>
      {openedChallenge >= 0 && quiz.challenge_type !== "Upload" && (
        <ModalQuiz
          quiz={quiz}
          finished={finishedDict[quiz.id]}
          perusahaanSlug={perusahaanSlug}
          setOpenChallenge={setOpenChallenge}
        />
      )}
      {account ? (
        <div className="play-id">
          <div>
            Play ID{` `}
            <HelpTooltip text="Used for custom challenges such as external surveys" />
          </div>
          <h2 style={{ marginTop: "12px" }}>
            {player.playId ? player.playId : "N/A"}{" "}
            <img
              src={vectorCopy}
              alt="Copy"
              onClick={() => {
                copy(player.playId ? player.playId : "N/A")
                toastActions.showToast("Play ID Copied")
              }}
            />
          </h2>
        </div>
      ) : (
        <div />
      )}
      <div className="challenge-points">
        <div className="points-title">
          <h2>Challenge Points</h2>
        </div>
        <div className="progress-challenge">
          <ProgressBar
            completed={
              currentPoint > maxPoint ? 100 : (currentPoint * 100) / maxPoint
            }
          />
          <small>
            {currentPoint} of {maxPoint} points earned
          </small>
        </div>
      </div>
      <br />
      {loadingQuiz ? (
        <h2 style={{ textAlign: "center" }}>Loading...</h2>
      ) : quizList.length === 0 ? (
        <h2 style={{ textAlign: "center" }}>No challenges to show</h2>
      ) : (
        quizList.map((current, index) => {
          const myPoint = pointGivenDict[current.id]
            ? `${pointGivenDict[current.id]}/`
            : ""
          return (
            <div
              key={index}
              className="one-quiz"
              onClick={() => {
                if (challengeClosedDict[current.id]) {
                  toastActions.showToast(
                    "Challenge not opened or already expired"
                  )
                } else {
                  setOpenChallenge(index)
                }
              }}
            >
              <img
                src={`/content/img/playground_check_${
                  finishedDict[current.id] ? "true" : "false"
                }.svg`}
                alt={finishedDict[current.id] ? "finished" : "not finished"}
              />
              <h2>
                {current.challenge_name} ({myPoint}
                {current.points} points)
              </h2>
              {finishedDict[current.id] ? <></> : <Button>Go</Button>}
            </div>
          )
        })
      )}
    </Styles>
  )
}

PerusahaanChallenge.propTypes = {
  id: PropTypes.string,
  perusahaanSlug: PropTypes.string,
  perusahaanName: PropTypes.string,
  perusahaanSosmed: PropTypes.object,
}

export default PerusahaanChallenge
