import React, { Component } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { FormattedMessage } from "react-intl"

import SmallNav from "../SmallNav"
import CompfestMarkdown from "../CompfestMarkdown"
import { Styles } from "./style"

import vectorCategory from "../../../assets/images/jobfair-assets/category.svg"
import vectorWebsite from "../../../assets/images/jobfair-assets/website.svg"
import vectorLokasi from "../../../assets/images/jobfair-assets/location.svg"
import vectorWhatsapp from "../../../assets/images/common/logo_whatsapp.svg"

export default class DetailPerusahaan extends Component {
  static propTypes = {
    perusahaan: PropTypes.object,
  }

  render() {
    const { perusahaan, children } = this.props

    const navItems = [
      {
        title: "Job Openings",
        to: `/job-fair/${perusahaan.slug}`,
        color: "linear-gradient(135deg, #EC006A 0%, #F66C3D 100%)",
      },
      {
        title: "Challenges",
        to: `/playground/${perusahaan.slug}`,
        color: "linear-gradient(135deg, #EC006A 0%, #F66C3D 100%)",
      },
    ]

    const medsos = Object.entries(
      JSON.parse(perusahaan.mediaSosial.internal.content)
    )

    return (
      <Styles>
        {perusahaan.nomorWhatsapp && (
          <div className="button-to-wa">
            <a
              href={`https://wa.me/${perusahaan.nomorWhatsapp}?text=Halo,%20Saya%20ingin%20bertanya`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={vectorWhatsapp} alt="WA" />
            </a>
          </div>
        )}
        <div className="top-section">
          <div className="company-logo">
            <Img fluid={perusahaan.logo.fluid} />
          </div>
          <div className="company-shortdesc">
            <h1>{perusahaan.nama}</h1>
            <div className="company-data">
              <div>
                <img src={vectorLokasi} alt="lokasi" />
                {perusahaan.lokasi}
              </div>
              <div>
                <img src={vectorCategory} alt="kategori" />
                {perusahaan.kategori}
              </div>
              <div>
                <img src={vectorWebsite} alt="website" />
                <a href={perusahaan.website} target="_blank" rel="noreferrer">
                  {perusahaan.website}
                </a>
              </div>
            </div>
            <div className="company-medsos">
              {medsos.map(([media, url], index) => (
                <div key={index}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={`/content/img/logo_${media.toLowerCase()}.svg`}
                      alt={media}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="company-longdesc">
          <h3>
            <FormattedMessage id="jobfair.applied.aboutTheCompany" />
          </h3>
          <CompfestMarkdown source={perusahaan.deskripsi.deskripsi} />
          {perusahaan.funfacts ? (
            <>
              <h3>Funfacts</h3>
              <CompfestMarkdown source={perusahaan.funfacts.funfacts} />
            </>
          ) : (
            <></>
          )}
        </div>
        <SmallNav navItems={navItems} />
        {children}
      </Styles>
    )
  }
}
