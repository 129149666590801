import React from "react"
import PropTypes from "prop-types"

import CloseSvg from "../../../../assets/images/common/close.svg"

import { ModalStyle, InnerModal, CloseStyle2, TransparentBox } from "./style"

const Modal = ({ children, onCancel }) => {
  return (
    <ModalStyle>
      <TransparentBox />
      <InnerModal>
        {children}
        <div className="close">
          <CloseStyle2 src={CloseSvg} onClick={onCancel} />
        </div>
      </InnerModal>
    </ModalStyle>
  )
}

Modal.propTypes = {
  children: PropTypes.object,
  onCancel: PropTypes.func,
}

export default Modal
