import styled from "styled-components"

export const Styles = styled.div`
  padding-bottom: 4rem;
  margin-left: 114px;
  margin-right: 114px;

  .button-to-wa {
    position: fixed;
    right: 30px;
    bottom: 15px;
  }

  .top-section {
    display: flex;
    align-items: center;

    a:hover {
      color: #999;
    }

    .company-logo {
      max-width: 260px;
      padding: 2rem 3rem 2rem 0;
      width: 100%;
    }

    .company-shortdesc {
      width: 100%;

      h1 {
        font-size: 3rem;
        margin-block-start: 0;
        margin-block-end: 0.5em;
      }
    }

    .company-data {
      font-family: "DM Sans", sans-serif;
      padding-bottom: 0.6rem;

      img {
        padding-right: 1rem;
        width: 12px;
      }

      & > * {
        padding-bottom: 1rem;
      }
    }

    .company-medsos {
      div:first-of-type {
        padding-left: 0;
      }

      div:last-of-type {
        padding-right: 0;
      }

      div {
        display: inline-block;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
      }
    }
  }

  .company-longdesc {
    padding-bottom: 2rem;

    h3 {
      padding-top: 1rem;
    }
  }

  .small-nav {
    justify-content: center;
  }

  @media screen and (max-width: 767px) {
    margin-right: 16px;
    margin-left: 16px;

    .button-to-wa {
      right: 15px;
      bottom: 5px;

      img {
        width: 100px;
      }
    }

    .top-section {
      align-items: flex-start;

      .company-logo {
        max-width: 40%;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 5%;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .top-section {
      flex-wrap: wrap;
      justify-content: center;

      .company-logo {
        max-width: 200px;
        width: 90%;
        padding: 0;
      }

      .company-shortdesc {
        h1 {
          margin-block-start: 0.5em;
          margin-block-end: 0.25em;
        }
      }
    }
  }
`
