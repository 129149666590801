import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Button from "../../Button"
import Fullpage from "../../fullpage"
import InputFile from "../../ApplicationForm/input-file"
import zeusAPI from "../../../api/zeus"
import InformationBlackscreen from "../InformationBlackscreen"
import { toastActions } from "../../../redux/toast"

import vectorIG from "./vector_instagram.svg"
import vectorDrive from "./vector_drive.svg"
import vectorSuccesful from "./vector_successful.svg"

const Styles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  min-height: calc(100% - 20px);

  h1 {
    font-size: 3rem;
    background: -webkit-linear-gradient(135deg, #ec006a 0%, #ffcb15 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .challenge-desc {
    padding-bottom: 30px;
    text-align: center;

    div {
      padding-bottom: 12px;
    }

    a {
      color: #fdd262;
    }

    a:hover {
      opacity: 0.75;
    }

    .button-to-ig {
      max-width: 260px;
      margin: auto;

      .jasper-button {
        margin-top: 10px;
      }

      img {
        position: relative;
        top: 3px;
        right: 6px;
      }
    }
  }

  .smaller-layout {
    max-width: 500px;
    margin: auto;

    .uploaded-data {
      display: block;
      text-align: center;
      padding-top: 70px;
      padding-bottom: 70px;
      background: rgba(10, 207, 131, 0.2);
      border: 2px solid #0acf83;
      box-sizing: border-box;
      border-radius: 5px;

      div:first-of-type {
        padding-bottom: 15px;
      }
    }

    .uploaded-data:hover {
      opacity: 0.75;
    }
  }

  .proof-follow {
    display: block;
    padding-bottom: 8px;
  }

  .jasper-button {
    margin-top: 40px;
    width: 100%;
  }

  button {
    width: 100%;
  }

  .u-sure {
    h2 {
      margin-bottom: 0;
      max-width: 650px;
      text-align: center;
    }

    .cancel-submit {
      text-align: center;

      h3 {
        padding-top: 1rem;
      }
    }

    .jasper-button {
      margin-left: 10px;
      margin-right: 10px;
      max-width: 150px;
    }

    button {
      max-width: 150px;
    }

    .btn-cancel {
      background: #eb5757;
    }

    .btn-submit {
      background: #0acf83;
    }
  }
`

const CompletedUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 0 3rem;

  .image__placeholder {
    margin-bottom: 3rem;
  }

  .completed__text {
    color: #0acf83;
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }

  .completed__description {
    font-size: 1.125rem;
  }

  @media (max-width: 768px) {
    .image__placeholder {
      width: 164px;
      height: 164px;
      margin-bottom: 2.25rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .completed__text {
      margin-bottom: 0.5rem;
    }
    .completed__description {
      font-size: 1rem;
    }
  }
`

function UploadChallenge({
  quiz,
  uploadedUrl,
  setOpenChallenge,
  perusahaanName,
  perusahaanSosmed,
}) {
  const [file, setFile] = useState({})
  const [uploadedData, setUploadedData] = useState("")
  const [showSuccessful, setShowSuccessful] = useState(false)
  const [uSure, setUSure] = useState(0)
  const cekDeskripsi = quiz.challenge_description.trim().split("_")

  const submitImage = async (id, file) => {
    try {
      setUSure(2)
      const submitData = await zeusAPI.playground.doUploadChallenge(id)
      const resp = await submitData.upload(file)
      if (resp.status >= 200 && resp.status <= 204) {
        setShowSuccessful(true)
        setUploadedData(resp)
        setTimeout(() => {
          setShowSuccessful(false)
          setUSure(0)
          setOpenChallenge(-1)
        }, 2000)
        toastActions.showToast("Upload successful!")
      } else {
        throw new Error()
      }
    } catch (e) {
      if (e.response.data?.detail) {
        toastActions.showToast(e.response.data.detail)
      } else {
        toastActions.showToast("Upload failed. Please try again later")
      }
      setOpenChallenge(-1)
    }
  }

  let autofillSosmed = ""
  let companyIg = ""
  if (cekDeskripsi[0] === "autofill" && cekDeskripsi.length > 1) {
    if (cekDeskripsi[1] === "instagram") {
      autofillSosmed = perusahaanSosmed[cekDeskripsi[1]]
    } else if (cekDeskripsi[1] === "igstory" && perusahaanSosmed["instagram"]) {
      companyIg = perusahaanSosmed["instagram"].split("/")
      if (companyIg[companyIg.length - 1] === "") {
        companyIg = companyIg[companyIg.length - 2]
      } else {
        companyIg = companyIg[companyIg.length - 1]
      }
    }
  }

  return showSuccessful ? (
    <InformationBlackscreen>
      <CompletedUpload>
        <div className="image__placeholder">
          <img src={vectorSuccesful} alt="succesful" />
        </div>
        <h1 className="completed__text">File Uploaded Successfully</h1>
        <h2 className="completed__description">
          Your points will be automatically updated as soon as our staff
          verifies your submission
        </h2>
      </CompletedUpload>
    </InformationBlackscreen>
  ) : (
    <Fullpage onClose={() => setOpenChallenge(-1)}>
      <Styles>
        {uSure ? (
          <div className="u-sure">
            <h2>
              Are you sure you want to submit this file? File submission cannot
              be editted again
            </h2>
            <div className="cancel-submit">
              {uSure === 2 ? (
                <h3>Uploading...</h3>
              ) : (
                <>
                  <Button className="btn-cancel" onClick={() => setUSure(0)}>
                    Cancel
                  </Button>
                  <Button
                    className="btn-submit"
                    onClick={() => submitImage(quiz.id, file)}
                  >
                    Submit
                  </Button>
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            <h1>{quiz.challenge_name.toUpperCase()}</h1>
            <div className="challenge-desc">
              {autofillSosmed ? (
                <>
                  <div>
                    Follow the company on{" "}
                    {cekDeskripsi[1][0].toUpperCase() +
                      cekDeskripsi[1].substring(1) +
                      " "}
                    and capture the proof before submitting it on the form
                    below!
                  </div>
                  {autofillSosmed && (
                    <div className="button-to-ig">
                      <Button
                        href={autofillSosmed}
                        target="_blank"
                        className="secondary"
                      >
                        <img src={vectorIG} alt="IG" />
                        {perusahaanName}
                      </Button>
                    </div>
                  )}
                </>
              ) : cekDeskripsi[1] === "igstory" ? (
                <>
                  <div>
                    Post the following image on your Instagram Story. Make sure
                    to tag @COMPFEST and
                    {companyIg ? ` @${companyIg}` : " the Company's IG"}.
                    Capture your story (as a proof) and submit it on the form
                    below
                  </div>
                  {cekDeskripsi.length > 2 && (
                    <div className="button-to-ig">
                      <Button
                        href={cekDeskripsi.slice(2).join("_")}
                        className="secondary"
                        target="_blank"
                      >
                        <img src={vectorDrive} alt="Drive" />
                        Download Image
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <div
                  style={{ lineHeight: "1.4" }}
                  dangerouslySetInnerHTML={{
                    __html: quiz.challenge_description,
                  }}
                />
              )}
            </div>
            <br />
            <div className="smaller-layout">
              <b className="proof-follow">Proof of Following</b>
              {uploadedUrl || !!uploadedData ? (
                <a
                  href={uploadedUrl}
                  className="uploaded-data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>File Uploaded</div>
                  <div>Click here to view</div>
                </a>
              ) : (
                <InputFile
                  name="upload_challenge"
                  maxSizeMB={5}
                  setFile={setFile}
                  extensions=".jpg,.png,.jpeg"
                  error={!file.name}
                />
              )}
              {uploadedUrl ? (
                <Button onClick={() => setOpenChallenge(-1)}>
                  Return to Challenges
                </Button>
              ) : (
                <Button onClick={() => setUSure(1)}>Submit</Button>
              )}
            </div>
          </div>
        )}
      </Styles>
    </Fullpage>
  )
}

UploadChallenge.propTypes = {
  quiz: PropTypes.object,
  uploadedUrl: PropTypes.string,
  setOpenChallenge: PropTypes.func,
  perusahaanName: PropTypes.string,
  perusahaanSosmed: PropTypes.object,
}

export default UploadChallenge
