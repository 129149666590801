import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import closeSvg from "../../assets/images/common/close.svg"

const FullPageStyle = styled.div`
  position: fixed;
  z-index: 300;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.background};
  overflow-y: auto;
`

const CloseStyle = styled.img`
  position: fixed !important;
  right: 40px;
  top: 40px;
  cursor: pointer;
  padding: 10px;

  @media (max-width: 576px) {
    right: 22px;
    top: 22px;
  }
`

const Fullpage = ({ onClose, children }) => (
  <FullPageStyle>
    <CloseStyle src={closeSvg} onClick={onClose} />
    {children}
  </FullPageStyle>
)

Fullpage.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default Fullpage
