import React, { useState } from "react"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import styled from "styled-components"

import { toastActions } from "../../redux/toast"

import upload from "../../../assets/images/common/upload.svg"
import pdfIcon from "../../../assets/images/common/pdf_green_icon.svg"

const StyledSection = styled.section`
  border: 2px dashed ${(props) => (props.error ? `#EB5757` : `#f3f3f3`)};
  border-radius: 5px;
  cursor: pointer;

  ${(props) =>
    props.submitted &&
    `
  background-color: rgba(10, 207, 131, 0.2);
  border: 2px solid #0ACF83;
  `}

  > div {
    height: 227px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  > div > p {
    font-size: 14px;
  }

  div.upload-file {
    margin-bottom: 12px;
    display: flex;

    p {
      margin-left: 12px;
    }
  }

  div.submitted-file {
    display: flex;
    align-items: center;
    margin: 8px 20px 16px;
    img {
      width: 25px;
    }

    p {
      font-size: 14px;
      margin-top: 4px;
      margin-left: 12px;
      text-align: center;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (max-width: 360px) {
        max-width: 100px;
      }
    }
  }
`

const InputFile = (props) => {
  const [file, setFile] = useState(props.file)

  return (
    <Dropzone
      accept={props.extensions}
      maxSize={props.maxSizeMB * 1048576}
      multiple={false}
      onDrop={(acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length === 1) {
          setFile(acceptedFiles[0])
          props.setFile && props.setFile(acceptedFiles[0])
        } else if (rejectedFiles[0].errors[0].code === "file-too-large") {
          toastActions.showToast(
            `Your file is larger than ${props.maxSizeMB} MB`
          )
        } else {
          toastActions.showToast(
            `Error select file '${rejectedFiles[0].errors[0].code}'`
          )
        }
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <StyledSection error={props.error} submitted={Boolean(file?.name)}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />

            {!file?.name ? (
              <>
                <div className="upload-file">
                  <img src={upload} alt="upload" />
                  <p>Upload File</p>
                </div>
                <p>Max Size: {props.maxSizeMB} MB</p>
                <p>Upload file in {props.extensions} format</p>
              </>
            ) : (
              <>
                <p>File Selected</p>
                <div className="submitted-file">
                  <img src={pdfIcon} alt="pdf" />
                  <p>{file.name}</p>
                </div>
                <p>
                  <i>Click to edit submission</i>
                </p>
              </>
            )}
          </div>
        </StyledSection>
      )}
    </Dropzone>
  )
}

InputFile.propTypes = {
  extensions: PropTypes.string,
  maxSizeMB: PropTypes.number,
  setFile: PropTypes.func,
  error: PropTypes.bool,
}

export default InputFile
