import styled from "styled-components"

export const Styles = styled.div`
  max-width: 1000px;
  margin: auto;

  .play-id {
    text-align: center;
    padding-top: 1rem;

    h2 {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      @supports ((background-clip: text) or (-webkit-background-clip: text)) {
        background-image: linear-gradient(135deg, #cb0000 0%, #ff0073 100%);
        background-size: cover;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
      }
    }

    img {
      position: relative;
      top: 5px;
      left: 5px;
      cursor: pointer;
    }
  }

  .challenge-points {
    max-width: 627px;
    margin: auto;
    background: linear-gradient(135deg, #ec006a 0%, #f66c3d 100%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    .points-title {
      width: 45%;

      h2 {
        max-width: 150px;
        margin: 0;
      }
    }

    .progress-challenge {
      text-align: center;
      width: 55%;

      small {
        display: inline-block;
        padding-top: 1rem;
      }
    }
  }

  .challenge-points:first-of-type {
    /* This is required because of a weird bug in build */
    background: transparent;
    display: block;
    text-align: center;
  }

  .one-quiz {
    background: #2e2e2e;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    img {
      padding-right: 15px;
    }

    h2 {
      width: 100%;
      padding-right: 15px;
    }

    button {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .one-quiz:hover {
    opacity: 0.75;
    cursor: pointer;

    button {
      opacity: 1;
    }
  }

  @media screen and (max-width: 424px) {
    .challenge-points {
      flex-wrap: wrap;
      padding: 1.5rem;

      .points-title {
        width: 100%;
        padding-bottom: 1.5rem;

        h2 {
          max-width: 100%;
          text-align: center;
        }
      }

      .progress-challenge {
        width: 100%;
        margin: auto;
      }
    }

    .one-quiz {
      padding: 1rem;

      button {
        padding-left: 22px;
        padding-right: 22px;
      }
    }
  }
`
