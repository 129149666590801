import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import Button from "../../Button"
import ConfirmPopupModal from "../ConfirmPopup/modal"
import zeusAPI from "../../../api/zeus"

const Styles = styled.div`
  width: 100%;

  .override-quiz {
    margin-top: -10px !important;
    margin-bottom: 5px !important;
    display: flex;
    padding: 0;

    img {
      padding-right: 15px;
    }

    h2 {
      width: 100%;
      padding-right: 15px;
    }
  }

  .start-btn {
    padding-top: 20px;
    text-align: center;

    button {
      max-width: 120px;
    }

    button:disabled {
      opacity: 0.75;
      cursor: unset;
    }

    button.finished {
      opacity: 1;
      background: transparent;
      color: #949494;
      border: 2px solid #949494;
    }

    button.finished:hover {
      opacity: 1;
    }
  }
`

function ModalQuiz({ quiz, setOpenChallenge, finished, perusahaanSlug }) {
  const [gForm, setGForm] = useState("")
  const [loading, setLoading] = useState(false)
  const isGForm = quiz.challenge_type === "GForm"

  const mulaiKuis = () => {
    setLoading(true)
    zeusAPI.playground.doChallenge(quiz.id).then(({ data }) => {
      navigate(
        `/playground/quiz?session=${data.quizSessionId}&slug=${perusahaanSlug}`
      )
    })
  }

  useEffect(() => {
    if (isGForm) {
      zeusAPI.playground.doChallenge(quiz.id).then(({ data }) => {
        setGForm(data.form)
      })
    }
  }, [])

  return (
    <ConfirmPopupModal onCancel={() => setOpenChallenge(-1)}>
      <Styles>
        <div className="override-quiz">
          <img
            src={`/content/img/playground_check_${
              finished ? "true" : "false"
            }.svg`}
            alt={finished ? "finished" : "not finished"}
          />
          <h2>{quiz.challenge_name}</h2>
        </div>
        <p>{quiz.challenge_description}</p>
        <div className="start-btn">
          {finished ? (
            <Button disabled className="finished">
              Finished
            </Button>
          ) : isGForm ? (
            gForm ? (
              <Button href={gForm} target="_blank">
                Start
              </Button>
            ) : (
              <Button disabled>Loading...</Button>
            )
          ) : loading ? (
            <Button disabled>Preparing...</Button>
          ) : (
            <Button onClick={mulaiKuis}>Start</Button>
          )}
        </div>
      </Styles>
    </ConfirmPopupModal>
  )
}

ModalQuiz.propTypes = {
  quiz: PropTypes.object,
  setOpenChallenge: PropTypes.func,
  finished: PropTypes.bool,
}

export default ModalQuiz
