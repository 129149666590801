import styled from "styled-components"

export const ModalStyle = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 201;

  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

export const InnerModal = styled.div`
  position: relative;
  border-radius: 10px;
  min-width: 360px;
  max-width: 840px;
  z-index: 10;
  background-color: #2e2e2e;

  display: flex;
  align-items: start;
  padding: 32px 20px;

  .close {
    padding-top: 5px;
  }

  @media (max-width: 576px) {
    p {
      font-size: 20px;
    }
  }
`

export const TransparentBox = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.8;
`

export const ConfirmPopUpStyle = styled.div`
  position: fixed;
  z-index: 201;
  width: calc(100% - 40px);
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.background};
  padding: 0 20px;
`

export const CloseStyle = styled.img`
  position: fixed !important;
  right: 40px;
  top: 40px;
  cursor: pointer;
  padding: 10px;

  @media (max-width: 576px) {
    right: 22px;
    top: 22px;
  }
`
export const CloseStyle2 = styled.img`
  // width: 65%;
  position: absolute;
  top: 2em;
  right: 2em;
  cursor: pointer;
`
