import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import questionSvg from "./question.svg"

const Styles = styled.div`
  display: inline-block;
  position: relative;

  img {
    cursor: pointer;
  }

  .ask-tooltip {
    position: absolute;
    left: 32px;
    top: 6px;
    background: #2e2e2e;
    border-radius: 10px;
    padding: 12px;
    width: 280px;
    text-align: left;
    z-index: 1;
  }

  @media screen and (max-width: 767px) {
    .ask-tooltip {
      left: ${(props) => (props.float === "right" ? "0" : "-126px")};
      top: 38px;
      width: 200px;
    }
  }
`

function HelpTooltip({ text, float }) {
  const [tooltipOpen, setTooltip] = useState(false)

  return (
    <Styles float={float}>
      <img
        src={questionSvg}
        alt="ask"
        onClick={() => setTooltip(!tooltipOpen)}
      />
      {tooltipOpen && <div className="ask-tooltip">{text}</div>}
    </Styles>
  )
}

HelpTooltip.propTypes = {
  text: PropTypes.string,
}

export default HelpTooltip
