import React from "react"
import PropTypes from "prop-types"

function ProgressBar({ completed, withlabel, fillerColor }) {
  if (!fillerColor) fillerColor = "#F3F3F3"

  const containerStyles = {
    height: 15,
    width: "100%",
    backgroundColor: "rgba(243, 243, 243, 0.5)",
    borderRadius: 50,
  }

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    background: fillerColor,
    borderRadius: "inherit",
    textAlign: "right",
    transition: "width 1s linear",
  }

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        {withlabel && <span style={labelStyles}>{`${completed}%`}</span>}
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  completed: PropTypes.number,
  withlabel: PropTypes.bool,
}

export default ProgressBar
