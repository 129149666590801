import React, { Component } from "react"
import PropTypes from "prop-types"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

import Style from "./style"

class SmallNav extends Component {
  render() {
    const navItems = this.props.navItems
    // const def = this.props.def
    return (
      <Style>
        <div className="small-nav">
          {navItems.map((navItem, index) => (
            <div key={index}>
              <Link
                to={navItem.to}
                style={{ opacity: ".5" }}
                activeStyle={{
                  opacity: "1",
                  fontWeight: "bold",
                  background: `${navItem.color}`,
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  color: "transparent",
                  paddingBottom: "4px",
                  borderBottom: "1px solid",
                  borderImageSource: `${navItem.color}`,
                  WebkitBorderImage: `${navItem.color}`,
                  borderImageSlice: "1",
                }}
                // activeStyle={{
                //   fontWeight: "bold",
                //   borderBottom: `1px solid ${navItem.color}`,
                //   color: `${navItem.color}`,
                // }}
              >
                {navItem.title}
              </Link>
            </div>
          ))}
        </div>
        {/* <div className="nav-content">
          {navItems[this.state.index].component}
        </div> */}
      </Style>
    )
  }
}

SmallNav.propTypes = {
  navItems: PropTypes.array.isRequired,
}

export default SmallNav
