import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailPerusahaan from "../components/DetailPerusahaan"
import PerusahaanChallenge from "../components/DetailPerusahaan/PerusahaanChallenge"

export default class PerusahaanChallengeTemplate extends Component {
  render() {
    const { data } = this.props

    const perusahaan = data.contentfulPerusahaan
    return (
      <Layout>
        <SEO title={perusahaan.nama} />
        {perusahaan && (
          <DetailPerusahaan perusahaan={perusahaan}>
            <PerusahaanChallenge
              id={perusahaan.backendId}
              perusahaanName={perusahaan.nama}
              perusahaanSlug={perusahaan.slug}
              perusahaanSosmed={JSON.parse(
                perusahaan.mediaSosial.internal.content
              )}
            />
          </DetailPerusahaan>
        )}
      </Layout>
    )
  }
}

export const query = graphql`
  query PerusahaanChallengeData($slug: String!) {
    contentfulPerusahaan(slug: { eq: $slug }) {
      backendId
      slug
      nama
      logo {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      lokasi
      kategori
      website
      nomorWhatsapp
      mediaSosial {
        internal {
          content
        }
      }
      deskripsi {
        deskripsi
      }
      funfacts {
        funfacts
      }
    }
  }
`
